import React from 'react';
import Wallet from './Wallet';

const Header: React.FC = () => {
  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-4 gap-2 pt-2">
        <div className="col-span-3">
          <span className="pe-3">
            <a href="/" className="text-4xl">MASSAMON</a>
          </span>
          <span className="p-3"><a href="/mint" className="text-red-700 hover:text-red-600">Mint</a></span>
          <span className="p-3"><a href="/collection" className="text-red-700 hover:text-red-600">My Collection</a></span>
          <span className="p-3"><a href="/battle" className="text-red-700 hover:text-red-600">Majestic Battle</a></span>
        </div>
        <div className="text-end hidden sm:block">
          <Wallet />
        </div>
      </div>
      <div className="py-12">
        <img alt="collection-banner" className="rounded shadow-xl" src="collection-banner.png" />
      </div>
    </div>
  );
}

export default Header;