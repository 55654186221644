import React, { useEffect, useState } from "react";
import { Args, fromMAS } from "@massalabs/massa-web3";
import Header from './Header';
import Footer from './Footer';
import { SC_ADDRESS, useWalletManagerContext } from './WalletManager'

const Collection: React.FC = () => {
  const { accounts, indexAccount, client, setErrorMessage } = useWalletManagerContext();
  const [NFT, setNFT] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [hiddenModal, setToggleModal] = useState<boolean>(true);
  const [tranferId, settranferId] = useState<number>(0);
  const [tranferAddress, setTranferAddress] = useState<string>();
  const [lastOpId, setLastOpId] = useState<string>();
  const [lastStatus, setLastStatus] = useState<boolean>();
  const [transferDisabled, setTransferDisabled] = useState<boolean>(false);

  const handleTransferAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    const addr = event.target.value.trim();
    setTranferAddress(addr);
  };

  const fetchOwnerNft = async (owner: string): Promise<any> => {
    try {
      const response = await fetch(`https://scanner.massamon.com/owner/${owner}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      for (const [index, element] of data.entries()) {
        const id = element.NFT_ID;
        try {
          const response = await fetch(`https://api.massamon.com/nft-m/${id}`);
          const METADATAS = await response.json();
          data[index] = { ...element, METADATAS };
        } catch (error) {
          console.error(`Error fetching data for NFT ID ${id}:`, error);
        }
      }
      
      setNFT(data);
      setLoading(false);
      console.log(data);
    } catch (error) {
      console.error("Error fetching owner data:", error);
      throw error;
    }
  };

  const tranfert = async () => {
    setErrorMessage(null);
    if (!client) return;
    try {
        let to = "";
        if(tranferAddress)
          to = tranferAddress;

        await client.publicApi().getAddresses([to]);

        const args = new Args();
        args.addString(accounts[indexAccount].address());
        args.addString(to);
        args.addU256(BigInt(tranferId));
        
        let opId = await client.smartContracts().callSmartContract({
            targetAddress: SC_ADDRESS,
            targetFunction: "transferFrom",
            parameter: args.serialize(),
            maxGas: BigInt("100000000"),
            coins: fromMAS(0),
            fee: fromMAS(0.01),
        });

        setTransferDisabled(true); 
        setLastOpId(opId);
        checkLastOP(opId);
    } catch (error: any) {
      setTransferDisabled(false);
      setErrorMessage(error.toString());
    }
  };

  const checkLastOP = async (opId:string) => {
    setErrorMessage(null);
    setLastStatus(undefined);
    if (!client || !opId) return false;
    try {
      while(true)
      {
        const interval = setInterval(async () => {
          let res = await client.publicApi().getOperations([opId]);
          if(res.length) {
            console.log(res[0]);
            if(res[0].op_exec_status != null) // && res[0].is_operation_final)
            {
              setLastStatus(res[0].op_exec_status);
              //setTransferDisabled(false);
              clearInterval(interval);
            }
          }
        }, 1000);
    
        return () => clearInterval(interval);
      }
    } catch (error) {
      setErrorMessage("Error during checking OP!");
      return false;
    }
}

  useEffect(() => {
    if(accounts.length > 0)
      fetchOwnerNft(accounts[indexAccount].address());
  }, [accounts]);

  return (
    <div className="container mx-auto px-3 lg:px-40 font-mono py-3">
      <Header />
      <div className="text-center pt-8">
        <div className="text-4xl font-bold uppercase pb-2">
          My NFT Collection
        </div>
        <div className="py-2 grid gap-4 md:grid-cols-2 xl:grid-cols-4 justify-center">
          {!NFT.length &&
            (accounts.length ?
              (loading ? 
                <div className="col-span-4">Loading..</div>
              :
                <div className="col-span-4">You don't have Massamon.</div>
              )
            :
              <div className="col-span-4">Please connect your wallet.</div>
            )
          }
          {NFT.map((item, index) => (
            <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow" key={index}>
              <a href={item.METADATAS.image} target="_blank" rel="noreferrer">
                  <img className="rounded-t-lg" src={item.METADATAS.image} alt={item.METADATAS.name} />
              </a>
              <div className="p-2">
                      <div>
                        {item.METADATAS.attributes.map((attr:{trait_type: string; value: string;}, idx:number) => (
                          <span key={idx}  className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded"><span className="font-bold">{attr.trait_type}</span> {attr.value}</span>
                        ))}
                      </div>
                      <h5 className="text-2xl font-bold tracking-tight text-gray-900">
                        #{item.NFT_ID}
                        <button onClick={() => {setToggleModal(!hiddenModal); settranferId(item.NFT_ID);}} className="text-red-700 hover:text-red-600 ms-2">
                          <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m16 10 3-3m0 0-3-3m3 3H5v3m3 4-3 3m0 0 3 3m-3-3h14v-3"/>
                          </svg>
                        </button>
                      </h5>
              </div>
            </div>
          ))}
          {!hiddenModal && tranferId !== 0 &&
            <div id="transfer-modal" aria-hidden="true" className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center h-screen bg-red-100/75">
                      <div className="relative p-4 w-full max-w-md max-h-full mx-auto">
                          <div className="relative bg-white rounded-lg shadow-lg">
                              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                                <h3 className="text-lg font-semibold text-gray-900">
                                  Transfer NFT #{tranferId}
                                </h3>
                                  <button type="button" onClick={() => {setToggleModal(!hiddenModal); settranferId(0); setLastOpId(undefined); setTransferDisabled(false);}} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center" data-modal-toggle="crypto-modal">
                                      <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7l-6 6" />
                                      </svg>
                                      <span className="sr-only">Close modal</span>
                                  </button>
                              </div>
                              <div className="p-4 md:p-5">Tranfer your NFT to another address.
                                  <ul className="my-4 space-y-3">
                                        <input onChange={handleTransferAddress} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5" placeholder="Paste address" />
                                          <button onClick={tranfert} disabled={transferDisabled} className={transferDisabled ? "flex items-center w-full p-3 py-6 bg-red-200 rounded text-white" : "flex items-center w-full p-3 py-6 bg-red-500 px-3 py-1 rounded text-white hover:bg-gradient-to-l hover:from-red-800 hover:ring-offset-2 hover:ring-2 hover:ring-red-300"}>
                                              <span className="flex-1 whitespace-nowrap">Continue</span>
                                          </button>
                                          {lastOpId && (
                                              <div className="mt-2">
                                                  <div>{lastStatus === undefined ? "Transfering.. ⏳" : (lastStatus ? "Transfered 🎉" : "FAILED ❌")}</div>
                                                  <div className="mt-1"><a className="text-red-700 hover:text-red-600" href={`https://explorer.massa.net/mainnet/operation/${lastOpId}`} target="blank_">See OP on explorer</a></div>
                                              </div>
                                              )
                                          }
                                  </ul>
                              </div>
                          </div>
                      </div>
            </div>
          }
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Collection;