import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Mint from './components/Mint';
import Collection from './components/Collection';
import Battle from './components/Battle';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/mint" element={<Mint />} />
        <Route path="/collection" element={<Collection />} />
        <Route path="/battle" element={<Battle />} />
        <Route path="*" element={<Mint />} />
      </Routes>
    </Router>
  );
}

export default App;