import React, { useEffect, useState } from "react";
import { ClientFactory, Args, fromMAS, toMAS, DefaultProviderUrls, CHAIN_ID, bytesToStr } from "@massalabs/massa-web3";
import Header from './Header';
import Footer from './Footer';
import { SC_ADDRESS, useWalletManagerContext } from './WalletManager'

const Collection: React.FC = () => {
  const { accounts, indexAccount, client, setErrorMessage } = useWalletManagerContext();
  const BASE_URI = "https://api.massamon.com/nft-p/";
  const SC_ADDRESS_MB = "AS12rp6iqJtmPQPCnNLNg67NZqVr975qE8mrMKHABJZZJUW6K3HcE";
  const urlNet = DefaultProviderUrls.MAINNET;
  const chainId = CHAIN_ID.MainNet;
  const [noWins, setNoWins] = useState<boolean>(true);
  const [wins, setWins] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [hiddenModal, setToggleModal] = useState<boolean>(true);
  const [claimTimeIndex, setClaimTimeIndex] = useState<number>(0);
  const [lastOpId, setLastOpId] = useState<string>();
  const [lastStatus, setLastStatus] = useState<boolean>();
  const [claimDisabled, setClaimDisabled] = useState<boolean>(false);

  const getWins = async () => {
    setWins([]);
    try {
      const clientWeb = await ClientFactory.createDefaultClient(urlNet, chainId, true);
      const res = await clientWeb.smartContracts().readSmartContract({
          maxGas: BigInt(1000000000),
          targetAddress: SC_ADDRESS_MB,
          targetFunction: "getWins",
          parameter: [],
      });
      
      const allWins = bytesToStr(res.returnValue).replace(/\u0000/g, '').split(',');

      setNoWins(false);
      setLoading(false);

      allWins.reverse();

      for (const win of allWins) {
        await getWin(win);
      }
    } catch {
      setNoWins(true);
      setLoading(false);
    }
  }

  const getWin = async (timeIndex: string) => {
    try {
      const clientWeb = await ClientFactory.createDefaultClient(urlNet, chainId, true);
      const res = await clientWeb.smartContracts().readSmartContract({
          maxGas: BigInt(1000000000),
          targetAddress: SC_ADDRESS_MB,
          targetFunction: "getWin",
          parameter: new Args().addString(timeIndex),
      });
      
      const winInfo = new Args(res.returnValue);
      const Nft = winInfo.nextU256();
      const Amount = winInfo.nextU64();
      const Claimed = winInfo.nextBool();
      const ClaimedAddress = winInfo.nextString();
      const owner = await getOwner(Nft);

      setWins(prevWins => {
        const exists = prevWins.some(win => win.timeIndex === timeIndex);
        if (!exists) {
          return [...prevWins, {timeIndex: timeIndex, nft: Nft.toString(), owner: owner, amount: toMAS(Amount).toNumber(), claimed: Claimed, claimedAddress: ClaimedAddress}];
        }
        return prevWins;
      });
    } catch {
      setErrorMessage("Error to retrieve win informations.");
    }
  }

  const getOwner = async (nft: bigint) => {
    try {
      const clientWeb = await ClientFactory.createDefaultClient(urlNet, chainId, true);
      const res = await clientWeb.smartContracts().readSmartContract({
          maxGas: BigInt(1000000000),
          targetAddress: SC_ADDRESS_MB,
          targetFunction: "getWinAddress",
          parameter: new Args().addU256(nft),
      });
      
      const owner = bytesToStr(res.returnValue).replace(/\u0000/g, '');
      return owner;
    } catch {
      setErrorMessage("Error to retrieve win informations.");
    }
  }

  const claim = async () => {
    setErrorMessage(null);
    if (!client) return;
    try {
        const args = new Args();
        args.addString(wins[0].timeIndex);
        
        let opId = await client.smartContracts().callSmartContract({
            targetAddress: SC_ADDRESS_MB,
            targetFunction: "claim",
            parameter: args.serialize(),
            maxGas: BigInt("4000000000"),
            coins: fromMAS(0),
            fee: fromMAS(0.01),
        });

        setClaimDisabled(true); 
        setLastOpId(opId);
        checkLastOP(opId);
    } catch (error: any) {
      setClaimDisabled(false);
      setErrorMessage(error.toString());
    }
  };

  const checkLastOP = async (opId:string) => {
    setErrorMessage(null);
    setLastStatus(undefined);
    if (!client || !opId) return false;
    try {
      while(true)
      {
        const interval = setInterval(async () => {
          let res = await client.publicApi().getOperations([opId]);
          if(res.length) {
            console.log(res[0]);
            if(res[0].op_exec_status != null)
            {
              setLastStatus(res[0].op_exec_status);
              clearInterval(interval);
            }
          }
        }, 1000);
    
        return () => clearInterval(interval);
      }
    } catch (error) {
      setErrorMessage("Error during checking OP!");
      return false;
    }
}

  useEffect(() => {
      getWins();
  }, []);

  return (
    <div className="container mx-auto px-3 lg:px-40 font-mono py-3">
      <Header />
      <div className="text-center pt-8">
        <div className="text-4xl font-bold uppercase pb-2">
          Majestic Battle
        </div>
        <div className="py-2 grid gap-4 md:grid-cols-2 xl:grid-cols-4 justify-center">
          {loading ?
            <div className="col-span-4">Loading..</div>
          :
            (noWins && <div className="col-span-4">Coming soon!</div>)
          }
          {wins.map((winner, index) => (
            (index === 0 ?
              <div className="m-auto max-w-96 col-auto md:max-w-sm md:col-span-2 xl:col-span-4 bg-white border border-gray-200 rounded-lg shadow" key={index}>
                <a href={BASE_URI+winner.nft} target="_blank" rel="noreferrer">
                    <img className="rounded-t-lg" src={BASE_URI+winner.nft} alt={winner.nft} />
                </a>
                <div className="p-2">
                        <div>
                          <span>Majestic Winner #{winner.nft}</span>
                        </div>
                        <h5 className="text-2xl font-bold tracking-tight text-gray-900">
                          {winner.amount} MAS
                        </h5>
                        <div>
                          {accounts[indexAccount] && winner.owner == accounts[indexAccount].address() && !winner.claimed ?
                            <button onClick={() => {setToggleModal(!hiddenModal); setClaimTimeIndex(winner.timeIndex);}} className="text-red-700 hover:text-red-600 ms-2">
                              CLAIM
                            </button>
                          :
                            <span className={winner.claimed?"bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded":"bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded"}>{winner.claimed?"Claimed":"Not claimed"}</span>
                          }
                        </div>
                </div>
              </div>
            :
              <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow" key={index}>
                <a href={BASE_URI+winner.nft} target="_blank" rel="noreferrer">
                    <img className="rounded-t-lg" src={BASE_URI+winner.nft} alt={winner.nft} />
                </a>
                <div className="p-2">
                        <div>
                          <span>Previous Winner #{winner.nft}</span>
                        </div>
                        <h5 className="text-2xl font-bold tracking-tight text-gray-900">
                          {winner.amount} MAS
                        </h5>
                </div>
              </div>
            )
          ))}
          {!hiddenModal && claimTimeIndex !== 0 &&
            <div id="transfer-modal" aria-hidden="true" className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center h-screen bg-red-100/75">
                      <div className="relative p-4 w-full max-w-md max-h-full mx-auto">
                          <div className="relative bg-white rounded-lg shadow-lg">
                              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                                <h3 className="text-lg font-semibold text-gray-900">
                                  Claim {wins[0].amount} MAS
                                </h3>
                                  <button type="button" onClick={() => {setToggleModal(!hiddenModal); setClaimTimeIndex(0); setLastOpId(undefined); setClaimDisabled(false); getWins();}} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center" data-modal-toggle="crypto-modal">
                                      <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7l-6 6" />
                                      </svg>
                                      <span className="sr-only">Close modal</span>
                                  </button>
                              </div>
                              <div className="p-4 md:p-5">
                                <div className="text-2xl font-bold">Congratulation!</div>
                                  <ul className="my-4 space-y-3">
                                          <button onClick={claim} disabled={claimDisabled} className={claimDisabled ? "flex items-center w-full p-3 py-6 bg-red-200 rounded text-white" : "flex items-center w-full p-3 py-6 bg-red-500 px-3 py-1 rounded text-white hover:bg-gradient-to-l hover:from-red-800 hover:ring-offset-2 hover:ring-2 hover:ring-red-300"}>
                                              <span className="flex-1 whitespace-nowrap">CLAIM</span>
                                          </button>
                                          {lastOpId && (
                                              <div className="mt-2">
                                                  <div>{lastStatus === undefined ? "Claiming.. ⏳" : (lastStatus ? "Claimed 🎉" : "FAILED ❌")}</div>
                                                  <div className="mt-1"><a className="text-red-700 hover:text-red-600" href={`https://explorer.massa.net/mainnet/operation/${lastOpId}`} target="blank_">See OP on explorer</a></div>
                                              </div>
                                              )
                                          }
                                  </ul>
                              </div>
                          </div>
                      </div>
            </div>
          }
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Collection;