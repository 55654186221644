import React, { useState } from 'react';
import { useWalletManagerContext, Provider, truncateAddress, useWalletSelecterContext } from './WalletManager'

const Wallet: React.FC = () => {
  const { account, accounts, indexAccount, errorMessage, providerSelection } = useWalletManagerContext();
  const { hiddenWalletSelecter, setHiddenWalletSelecter } = useWalletSelecterContext();
  const [selectedAccount, setSelectedAccount] = useState<string>();

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedAddress = event.target.value;
    const selectedIndex = accounts.findIndex(accounts => accounts.address() === selectedAddress);
    setSelectedAccount(selectedAddress);

    providerSelection(Provider.MASSASTATION, selectedIndex);
  };

  return (
    <div className="h-full">
      {account ? (
        <div className="truncate h-full">
          {accounts.length > 1 && (
              <div className="h-full flex items-end justify-end">
                <select value={selectedAccount} onChange={handleChange} className="bg-red-50 border border-gray-300 text-gray-900 text-sm rounded-lg hover:border-red-800 p-2.5" aria-label="accountWallet">
                  {accounts.map((account, index) => (
                    <option key={index} value={account.address()} selected={account.address() === accounts[indexAccount].address()}>{truncateAddress(account.address())}</option>
                  ))}
                </select>
                <span className="ms-1">
                  <button className="text-red-700 hover:text-red-600 mt-1" onClick={() => setHiddenWalletSelecter(!hiddenWalletSelecter)}>
                    <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"/>
                    </svg>
                  </button>
                </span>
              </div>
              
          )}
          {accounts.length === 1 && (
            <div className="h-full flex items-end justify-end">
              <div className="flex items-center">
                <span>{truncateAddress(accounts[0].address())}</span>
                <span className="ms-1">
                  <button className="text-red-700 hover:text-red-600 mt-1" onClick={() => setHiddenWalletSelecter(!hiddenWalletSelecter)}>
                    <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"/>
                    </svg>
                  </button>
                </span>
              </div>
            </div>
          )}
        </div>
      ):
      <div>
        <button onClick={() => setHiddenWalletSelecter(!hiddenWalletSelecter)} className="bg-red-500 px-3 py-1 rounded text-white hover:bg-gradient-to-l hover:from-red-800 hover:ring-offset-2 hover:ring-2 hover:ring-red-300">Connect wallet</button>
      </div>
      }
      {errorMessage && <div className="fixed start-5 bottom-5 opacity-95 p-4 text-red-900 bg-red-50 rounded-lg shadow">{errorMessage}</div>}
    </div>
  );
}

export default Wallet;