import React from "react";
import Header from './Header';
import Faq from './Faq'
import Footer from './Footer';
import MintButton from './MintButton'

const Mint: React.FC = () => {
  return (
    <div className="container mx-auto px-3 lg:px-40 font-mono py-3">
      <Header />
      <div className="text-center pt-10">
        <div className="text-4xl font-bold uppercase pb-2">Welcome to Massamon!</div>
        <div className="py-2">Dive into the world of digital creatures where every Massamon is securely stored on the revolutionary Massa Blockchain.</div>
        <div className="py-2"><span className="font-bold">Collect</span>, <span className="font-bold">Trade</span> and <span className="font-bold">Earn</span> your way to victory with the most beautiful and exciting NFTs in the digital realm.</div>
        <div className="py-2"><span className="font-bold">Join the Massamon Revolution</span> today and become a part of the future of NFT on blockchain technology.</div>
        <div className="py-2"><span className="font-bold">Unleash the Power of Blockchain</span> with Massamon - where imagination meets NFT!</div>
        <div className="my-12 pt-5">
          <MintButton />
        </div>
      </div>
      <Faq />
      <Footer />
    </div>
  );
}

export default Mint;