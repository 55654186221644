import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import {
    IAccount,
    IProvider,
    providers
} from "@massalabs/wallet-provider";
import {
    ClientFactory,
    Client
} from "@massalabs/massa-web3";

export enum Provider {
    MASSASTATION = "MASSASTATION",
    BEARBY = "BEARBY",
}

export const NETWORK = "MAINNET";
export const SC_ADDRESS = "AS12qzDLDCYsUw4zCqBqirHcu4YGnoXsztQZ2mfx9atdL6z7ScfFr";

interface WalletManagerContextProps {
    errorMessage: string | null;
    client: Client | null;
    provider: IProvider | null;
    network: string | null;
    account: IAccount | null;
    accounts: IAccount[];
    indexAccount: number | 0;
    setErrorMessage: (message: string | null) => void;
    providerSelection: (providerName: Provider, accountSelection?: number) => Promise<void>;
}

const WalletManagerContext = createContext<WalletManagerContextProps | undefined>(undefined);

export const WalletManagerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [client, setClient] = useState<Client | null>(null);
    const [provider, setProvider] = useState<IProvider | null>(null);
    const [network, setNetwork] = useState<string | null>(null);
    const [account, setAccount] = useState<IAccount | null>(null);
    const [accounts, setAccounts] = useState<IAccount[]>([]);
    const [indexAccount, setIndexAccount] = useState<number | 0>(0);

    const initialize = async () => {
        setErrorMessage(null);
        try {
            const storedProvider = localStorage.getItem('provider');
            const storedIndexAccount = localStorage.getItem('indexAccount');
            let indexAccount = 0
            if(storedIndexAccount)
                indexAccount = parseInt(storedIndexAccount);

            if (storedProvider) {
                if (storedProvider === Provider.MASSASTATION) {
                    await providerSelection(Provider.MASSASTATION, indexAccount);
                } else if (storedProvider === Provider.BEARBY) {
                    await providerSelection(Provider.BEARBY);
                }
            }
        } catch {}
    };


    useEffect(() => {
        initialize();
    }, []);

    const providerSelection = async (providerName: Provider, accountSelection: number = 0) => {
        setErrorMessage(null);

        const providersList = await providers();
        const selectedProvider = providersList.find(
            (p) => p.name() === providerName
        );

        if (selectedProvider === undefined) {
            setErrorMessage("Please install MassaStation, plugin 'Massa Wallet' and refresh.");
            return;
        }

        try {
            let accounts = await selectedProvider.accounts();

            if (accounts.length === 0) {
                setErrorMessage("No accounts found.");
                return;
            }

            if (providerName === Provider.BEARBY) {
                do {
                    await selectedProvider.connect();
                    accounts = await selectedProvider.accounts();
                } while (accounts[accountSelection].address() === undefined);
            }

            setClient(await ClientFactory.fromWalletProvider(selectedProvider, accounts[accountSelection]));
            let network = await selectedProvider.getNetwork();

            setProvider(selectedProvider);
            setNetwork(network);
            setAccount(accounts[accountSelection]);
            setAccounts(accounts);
            setErrorMessage(null);

            localStorage.setItem('provider', providerName);
            localStorage.setItem('indexAccount', accountSelection.toString());
            setIndexAccount(accountSelection);

            if (network.toUpperCase() !== NETWORK)
                setErrorMessage("Change network to " + NETWORK + " and refresh.");
            else
                setErrorMessage(null);
        } catch (error: any) {
            setErrorMessage(error.toString());
            localStorage.removeItem('provider');
        }
    };

    return (
        <WalletManagerContext.Provider value={{
            errorMessage,
            client,
            provider,
            network,
            account,
            accounts,
            indexAccount,
            setErrorMessage,
            providerSelection
        }}>
            {children}
        </WalletManagerContext.Provider>
    );
};

export const useWalletManagerContext = () => {
    const context = useContext(WalletManagerContext);
    if (!context) {
        throw new Error("useWalletManagerContext must be used within an WalletManagerProvider");
    }
    return context;
};

export const truncateAddress = (address: string, overflowReplacement = "…") => {
    const start = address.substring(0, 4);
    const end = address.length > 4 ? address.substring(address.length - 4) : '';
    return start + overflowReplacement + end;
};

interface WalletSelecterContextProps {
    hiddenWalletSelecter: boolean;
    setHiddenWalletSelecter: (hidden: boolean) => void;
}

const WalletSelecterContext = createContext<WalletSelecterContextProps | undefined>(undefined);

export const WalletSelecterProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { providerSelection } = useWalletManagerContext();
    const [hiddenWalletSelecter, setHiddenWalletSelecter] = useState<boolean>(true);

    const toggleModal = () => {
        setHiddenWalletSelecter(!hiddenWalletSelecter);
    };

    const handleProviderSelection = async (provider: Provider) => {
        await providerSelection(provider);
        toggleModal();
    };

    return (
        <WalletSelecterContext.Provider value={{ hiddenWalletSelecter, setHiddenWalletSelecter }}>
            {children}
            {!hiddenWalletSelecter && (
                <div id="crypto-modal" aria-hidden="true" className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center h-screen bg-red-100/75">
                    <div className="relative p-4 w-full max-w-md max-h-full mx-auto">
                        <div className="relative bg-white rounded-lg shadow-lg">
                            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                                <h3 className="text-lg font-semibold text-gray-900">
                                    Select wallet
                                </h3>
                                <button type="button" onClick={toggleModal} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center" data-modal-toggle="crypto-modal">
                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                            <div className="p-4 md:p-5">
                                <ul className="my-4 space-y-3">
                                    <li>
                                        <button onClick={() => handleProviderSelection(Provider.BEARBY)} className="flex items-center w-full p-3 py-6 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                                            <span className="flex-1 whitespace-nowrap">Bearby</span>
                                        </button>
                                    </li>
                                    <li>
                                        <button onClick={() => handleProviderSelection(Provider.MASSASTATION)} className="flex items-center w-full p-3 py-6 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                                            <span className="flex-1 whitespace-nowrap">Massa Station</span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </WalletSelecterContext.Provider>
    );
};

export const useWalletSelecterContext = () => {
    const context = useContext(WalletSelecterContext);
    if (!context) {
        throw new Error("useWalletSelecterContext must be used within a WalletSelecterProvider");
    }
    return context;
};
