import React from 'react';

const Footer: React.FC = () => {
  return (
    <div>
      <div className="pt-10 mt-10 pb-4 text-sm text-center">
        <h1>&copy; <a href="/" className="text-red-700 hover:text-red-600">Massamon 2024</a> - <a href="https://t.me/xLaPerle" target="_blank" rel="noreferrer" className="text-red-700 hover:text-red-600">Contact</a></h1>
      </div>
    </div>
  );
}

export default Footer;