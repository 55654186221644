import React, { useEffect, useState } from 'react';
import { Args, fromMAS, bytesToU256 } from "@massalabs/massa-web3";
import { NETWORK, SC_ADDRESS, useWalletManagerContext, useWalletSelecterContext } from './WalletManager'

const MintButton: React.FC = () => {
    const { account, accounts, indexAccount, network, client, setErrorMessage } = useWalletManagerContext();
    const { hiddenWalletSelecter, setHiddenWalletSelecter } = useWalletSelecterContext();
    const [currentSupply, setCurrentSupply] = useState<number>(0);
    const [lastOpId, setLastOpId] = useState<string>();
    const [lastStatus, setLastStatus] = useState<boolean>();
    const [buyDisabled, setBuyDisabled] = useState<boolean>();

    const getCurrentSupply = async () => {
      if (!client) return setCurrentSupply(0);
      try {
        const res = await client.smartContracts().readSmartContract({
            maxGas: BigInt(100000000),
            targetAddress: SC_ADDRESS,
            targetFunction: "currentSupply",
            parameter: [],
        });
        const cSupply = bytesToU256(res.returnValue);
        return setCurrentSupply(parseInt(cSupply.toString()));
      } catch {
        return setCurrentSupply(0);
      }
    }

    const mint = async () => {
        setErrorMessage(null);
        if (!client) return;
        try {
            const args = new Args();
            args.addString(accounts[indexAccount].address())
            
            let opId = await client.smartContracts().callSmartContract({
                targetAddress: SC_ADDRESS,
                targetFunction: "mint",
                parameter: args.serialize(),
                maxGas: BigInt(100000000),
                coins: fromMAS(200),
                fee: fromMAS(0.01),
            });

            setBuyDisabled(true); 
            setLastOpId(opId);
            checkLastOP(opId);
        } catch (error: any) {
          if (error.message.includes("enough balance to pay for the coins")) {
            setBuyDisabled(false);
            try {
              const balance = await client.publicApi().getAddresses([accounts[indexAccount].address()]);
              setErrorMessage("You need a minimum of 200 MAS. (Balance: " + balance[0].final_balance + ")");
            }
            catch (error: any) {
              setErrorMessage("You need a minimum of 200 MAS.");
            }
          }
          else
          {
            setErrorMessage(error.toString());
          }
        }
    };

    const checkLastOP = async (opId:string) => {
        setErrorMessage(null);
        setLastStatus(undefined);
        if (!client || !opId) return false;
        try {
          while(true)
          {
            const interval = setInterval(async () => {
              let res = await client.publicApi().getOperations([opId]);
              if(res.length) {
                console.log(res[0]);
                if(res[0].op_exec_status != null) // && res[0].is_operation_final)
                {
                  setLastStatus(res[0].op_exec_status);
                  setBuyDisabled(false);
                  clearInterval(interval);
                }
              }
            }, 1000);
        
            return () => clearInterval(interval);
          }
        } catch (error) {
          setErrorMessage("Error during checking OP!");
          return false;
        }
    }

    useEffect(() => {
      getCurrentSupply();
      const interval = setInterval(() => {
        getCurrentSupply();
      }, 3000);
  
      return () => clearInterval(interval);
    });

    return (
        <div>
            {account ? (
                network && network.toUpperCase() === NETWORK ? (
                    <div>
                        <button onClick={mint} disabled={(buyDisabled || currentSupply >= 555) ? true : false} className={(buyDisabled || currentSupply >= 555) ? "bg-red-200 px-10 py-5 rounded text-white text-4xl" : "bg-red-500 px-10 py-5 rounded text-white text-4xl hover:bg-gradient-to-l hover:from-red-800 hover:ring-offset-2 hover:ring-2 hover:ring-red-300"}><p>MINT NOW</p><p className="text-sm text-red-300">(200 MAS)</p></button>
                        {lastOpId && (
                            <div className="mt-2">
                                <div>{lastStatus === undefined ? "Minting.. ⏳" : (lastStatus ? (<span>Minted 🎉 <a className="text-red-700 hover:text-red-600" href="/collection">See my NFT</a></span>) : "FAILED ❌")}</div>
                                <div className="mt-1"><a className="text-red-700 hover:text-red-600" href={`https://explorer.massa.net/mainnet/operation/${lastOpId}`} target="blank_">See OP on explorer</a></div>
                            </div>
                            )
                        }
                  </div>
                ) : (
                    <button disabled className="bg-red-200 px-10 py-5 rounded text-white text-4xl">Bad Network</button>
                )
            ):
                <button onClick={() => setHiddenWalletSelecter(!hiddenWalletSelecter)} className="bg-red-500 px-10 py-5 rounded text-white text-4xl hover:bg-gradient-to-l hover:from-red-800 hover:ring-offset-2 hover:ring-2 hover:ring-red-300">Connect wallet</button>
            }
          <div>
            {currentSupply ?
              <div className="py-3">Minted: <span className="font-bold">{currentSupply ? currentSupply : "-"}/555</span></div>
            :
              <div className="py-3">Please connect your wallet.</div>
            }
          </div>
        </div>
    );
}

export default MintButton;