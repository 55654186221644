import React from 'react';

const Faq: React.FC = () => {
  return (
    <div className="pt-10 mt-10 text-sm text-center">
        <div className="text-gray-900">
            <h2>
                <span className="flex justify-center py-3 px-3 text-gray-500 border-b border-gray-200 font-bold">
                    What is Massamon?
                </span>
            </h2>
            <div className="px-5 py-3 mb-5">
                <p className="mb-2 text-gray-500">
                Massamon is a groundbreaking digital collectible NFT where anyone can mint, trade, and battle unique creatures, all securely stored on the innovative Massa Blockchain. Each Massamon participe one time by month to a majectic battle.  Dive into the Massamon universe and experience the future of blockchain technology today!
                </p>
            </div>
            <h2>
                <span className="flex justify-center py-3 px-3 text-gray-500 border-b border-gray-200 font-bold">
                    Massamons are transferable and tradable?
                </span>
            </h2>
            <div className="px-5 py-3 mb-5">
                <p className="mb-2 text-gray-500">
                    <p>Yes, Massamons are designed to be transferable, allowing users to securely send and receive them between wallets.</p>
                    <p>Moreover, Massamons are tradable on the Purrfect Universe marketplace.</p>
                </p>
            </div>
            <h2>
                <span className="flex justify-center py-3 px-3 text-gray-500 border-b border-gray-200 font-bold">
                    What is "Majectic Battle"?
                </span>
            </h2>
            <div className="px-5 py-3 mb-5">
                <p className="mb-2 text-gray-500">
                    <p>
                        "Majestic Battle" is a competition where all Massamons come together, and one winner can earn up to 2,000 MAS. Immerse yourself in the excitement of this epic battle and find out who will emerge as the champion every month!
                    </p>
                    <p>
                        The winner can claim their prize on <a href="/battle" className="text-red-700 hover:text-red-600">Majestic Battle</a> by clicking on "CLAIM".
                    </p>
                    <p>After 30 days, the prize will no longer be claimable.</p>
                </p>
            </div>
            <h2>
                <span className="flex justify-center py-3 px-3 text-gray-500 border-b border-gray-200 font-bold">
                    How many Massamons are there?
                </span>
            </h2>
            <div className="px-5 py-3 mb-5">
                <p className="mb-2 text-gray-500">
                    <p className="mb-1">There are a total of 555 Massamons:</p>
                    <p>5 Shiny (5 x 1), each one is unique and majestic</p>
                    <p>25 Unique (25 x 1), each one is unique and beautiful</p>
                    <p>525 Basic (105 x 5), five copies for each</p>
                </p>
            </div>
        </div>
    </div>
  );
}

export default Faq;